import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/AlreadySelectPage.css';
import Axios from 'axios';

const AlreadySelectPage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const {setStep, userData, setUserData} = useContext(multiStepContext);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const statusId = userData.statusId;
  const [type, setType] = useState("");
  const [purpose, setPurpose] = useState("")

  const getConfirmData = async (statusId) => {
    const response = await Axios.get(apiURL+`checkOperationStatus/${statusId}`);
    setType(response.data);
}

const getPurpose = async (statusId) => {
  const response = await Axios.get(apiURL+`getOperationRecord/${statusId}`);
  setPurpose(response.data);
}

  const fname = type.disabled && type.disabled.firstName;
  const lname = type.disabled && type.disabled.lastName;
  const serviceDetail = type && type.OpInfo[0].operators.company_name;
  const packageType = type && type.OpInfo[0].packages.packageName;
  const numberDetail = type && type.OpInfo[0].phoneNumber;
  const purposeNumber = purpose && purpose.purpose
  const location = type && type.OpInfo && type.OpInfo[0] && type.OpInfo[0].branchs && type.OpInfo[0].branchs.name;

  useEffect(() => {
    if (statusId) {
      getConfirmData(statusId);  
      getPurpose(statusId);  
    }
  }, [statusId]);

  const handleLocation = () => {
    if (purposeNumber === "ใช่") {
      return (
        <div className='acc-phone'>
            รับซิมการ์ดที่ศูนย์บริการ
            <input type="text" className='already-text' value={location} readOnly></input>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderPurpose = () => {
    if(numberDetail){ return numberDetail;}
    else{ return 'รับซิมการ์ดใหม่ที่สาขา'}
  };

  return (
    <div className="already-container">
        <div className='already-info'>
            ท่านได้ยืนยันสิทธิ์เป็นที่เรียบร้อย
            <br/>
            <span style={{ fontSize: '16' }}>You've already confirmed</span>
        </div>
        <div className="already-info-con">
          <div className="already-fullname">
            <div className="already-fname">
              ชื่อ
              <input type="text" className='already-text' value={fname} readOnly></input>
            </div>
            <div className="already-lname">
              นามสกุล
              <input type="text" className='already-text' value={lname} readOnly></input>
          </div>
          </div>
          <div className="already-service-detail">
                <div className="already-service">
                  เครือข่าย
                  <input type="text" className='already-text' value={serviceDetail} readOnly></input>
                </div>
                <div className="already-phone">
                  หมายเลขโทรศัพท์
                  <input type="text" className='already-text' value={renderPurpose()} readOnly></input>
                </div>
          </div>
          <div className='already-package'>
            <div className="already-service">
                แพ็คแกจ
                <input type="text" className='already-text' value={packageType} readOnly></input>
            </div>
            {handleLocation()}
          </div>
        </div>
        <div className="already-register-con">
            <button onClick={() => setStep(1)} className="already-register-bt">กลับสู่หน้าหลัก</button>
        </div>
    </div>
  )
}

export default AlreadySelectPage;