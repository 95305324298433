import React from 'react'
import '../Styles/SidebarFooter.css'
import aisSideLogo from '../assets/ais-logo-side.png';
import dtacSideLogo from '../assets/dtac-logo-side.png';
import trueSideLogo from '../assets/true-logo-side.png';

const SidebarFooter = () => {
  return (
    <div className="side-logo">
        <img src={aisSideLogo} alt='aisLogo'></img>
        <img src={trueSideLogo} alt='trueLogo'></img>
        <img src={dtacSideLogo}alt='dtacLogo'></img>
    </div>
  )
}

export default SidebarFooter