import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/SelectTypePage.css';
import TypeProcessBar from '../assets/reg-process-bar.png';

const SelectTypePage = () => {
  const { setStep, userData, setUserData } = useContext(multiStepContext);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (userData.selectedOption) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [userData]);

  const handleOptionChange = (option) => {
    setUserData({ ...userData, "selectedOption": option });
    setIsChecked(true);
  };

  const handleNextStep = () => {
    if (!isChecked) {
      alert("Please select at least one option.");
      return;
    }
    setStep(4);
  };

  return (
    <div className="type-outer">
      <div className="type-container">
        <div className="type-process-bar">
            <img src={TypeProcessBar} className="type-pro-bar" alt="Registration Process Bar" />
        </div>
        <div className="type-info">
            โปรดเลือกตัวเลือกที่สอดคล้องกับท่านมากที่สุด
            <br />
            <span style={{ fontSize: '16' }}>Please indicate the most suitable option</span>
        </div>
        <div className="option-container">
          <div className="type-cb-op">
            <label>
              <input
                type="checkbox"
                checked={userData['selectedOption'] === 1}
                onChange={() => handleOptionChange(1)}
              />
              พิการทางการเห็น (Visually Impaired)
            </label>
          </div>
          <div className="type-cb-op">
            <label>
              <input
                type="checkbox"
                checked={userData['selectedOption'] === 2}
                onChange={() => handleOptionChange(2)}
              />
              พิการทางการได้ยินหรือสื่อความหมาย (Hearing Loss or Inability to Communicate)
            </label>
          </div>
          <div className="mobile-cb-op">
            <label>
              <input
                type="checkbox"
                checked={userData['selectedOption'] === 2}
                onChange={() => handleOptionChange(2)}
              />
             พิการทางการได้ยินหรือสื่อความหมาย (Hearing Loss or Inability to Communicate)
            </label>
          </div>
          <div className="type-cb-op">
            <label>
              <input
                type="checkbox"
                checked={userData['selectedOption'] === 3}
                onChange={() => handleOptionChange(3)}
              />
              พิการทางการเคลื่อนไหวหรือทางร่างกาย (Mobility Impairement)
            </label>
          </div>
          <div className="mobile-cb-op">
            <label>
              <input
                type="checkbox"
                checked={userData['selectedOption'] === 3}
                onChange={() => handleOptionChange(3)}
              />
              พิการทางการเคลื่อนไหวหรือทางร่างกาย (Mobility Impairement)
            </label>
          </div>
          <div className="type-cb-op">
            <label>
              <input
                type="checkbox"
                checked={userData['selectedOption'] === 4}
                onChange={() => handleOptionChange(4)}
              />
              <span className="label-text">
                พิการด้านจิตใจหรือพฤติกรรม พิการทางสติปัญญา พิการทางการเรียนรู้ หรือพิการทางออทิสติก
                (Emotional / Behavioral Disorders, Learning Disorders, or Autism Spectrum Disorders)
              </span>
            </label>
          </div>
          <div className="mobile-cb-op">
            <label>
              <input
                type="checkbox"
                checked={userData['selectedOption'] === 4}
                onChange={() => handleOptionChange(4)}
              />
              <span className="label-text">
                พิการด้านจิตใจหรือพฤติกรรม พิการทางสติปัญญา พิการทางการเรียนรู้ หรือพิการทางออทิสติก
                (Emotional / Behavioral Disorders, Learning Disorders, or Autism Spectrum Disorders)
              </span>
            </label>
          </div>
        </div>
        <div className="type-bt-container">
          <button onClick={handleNextStep} className="type-form-bt">ต่อไป</button>
        </div>
      </div>
    </div>
  );
};

export default SelectTypePage;
