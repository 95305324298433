import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/DetailPage.css';
import brandPackage from '../assets/brand-package.png';
import Axios from 'axios';

const DetailPage = () => {
    const apiURL = process.env.REACT_APP_API_URL;
    const {setStep, userData, finalData, setUserData} = useContext(multiStepContext);
    const latestData = finalData[finalData.length - 1];
    const statusId = userData.statusId;

    const [type, setType] = useState("");
    
    const getServiceAndNum = async (statusId) => {
        const response = await Axios.get(apiURL+`getOperationRecord/${statusId}`);
        setType(response.data);
    }
    
    useEffect(() => {
        if (statusId) {
            getServiceAndNum(statusId);     
        }
      }, [statusId]);

    const serviceDetail = type && type.OpInfo[0].operators.company_name;
    const numberDetail = type && type.OpInfo[0].phoneNumber;
    const imgDetail = type && type.OpInfo[0].packages.Banners;
    const purposeNumber = type && type.purpose
    const serviceID = type && type.OpInfo[0].operators.id;

    const renderPurpose = () => {
        if(numberDetail){ return numberDetail;}
        else{ return 'รับซิมการ์ดใหม่ที่สาขา'}
    };

    
    const handleDetailStep = () => {
        if (purposeNumber === "ไม่ใช่") {
            setUserData({ ...userData, purpose: purposeNumber }); 
            setStep(13);
        } else {
            setStep(12);
        }
        setUserData({ ...userData, serviceID: serviceID})
    };

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
        setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    return(
        <>
            <div className="detail-body">
                <div className="detail-container">
                    <div className="detail-area-con">
                        <div className="detail-info">
                            ข้อมูลหมายเลขของท่าน
                            <br/>
                            <span style={{ fontSize: '16' }}>Your Number Details</span> 
                        </div>
                        <div className="detail-fill-area">
                            <div className="detail-brand-phone">
                                <div className="detail-brand">
                                    เครือข่าย
                                    <input type="text" className='detail-text' value={serviceDetail} readOnly></input>
                                </div>
                                <div className="detail-phone">
                                    หมายเลขโทรศัพท์
                                    <input type="text" className='detail-text' value={renderPurpose()} readOnly></input>
                                </div>
                            </div>
                            <div className="center-img">
                                <img src={imgDetail} className={`detail-img ${windowHeight < 650 ? 'detail-small' : 'detail-img'}`} alt="Brand Package" />
                            </div>
                            <div className='detail-submit-area'>
                                <label>
                                    ข้อมูลไม่ถูกต้อง?  &nbsp;&nbsp;<a href="#" className="to-service">คลิกเพื่อติดต่อเจ้าหน้าที่</a>
                                </label>
                                <button onClick={handleDetailStep} className="to-location-button">
                                    ยืนยันข้อมูล
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </> 
    );
}

export default DetailPage;
