import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/CompleteRegisterPage.css';
import completeIcon from '../assets/successReg-img.png';

const CompleteRegisterPage = () => {
  const {setStep, userData, setUserData} = useContext(multiStepContext);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <div className="complete-container">
        <div className='complete-regis'>
            ระบบได้รับข้อมูลของท่านแล้ว
            <br/>
            <span style={{ fontSize: '16' }}>You're good to go</span>
        </div>
        <div className="complete-img-container">
          <img src={completeIcon} className={`complete-reg-img ${windowHeight < 650 ? 'hidden-notFound' : 'complete-reg-img'}`}></img>
        </div>
        <div className="complete-text">
        </div>
        <div className="complete-register-con">
            <button onClick={() => setStep(1)} className="complete-register-bt">กลับสู่หน้าหลัก</button>
        </div>
    </div>
  )
}

export default CompleteRegisterPage