import React, { useState, useEffect } from 'react';
import '../Styles/MobileHeader.css'
import nbtcLogo from '../assets/nbtc-logo.png';

const MobileHeader = () => {

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`mobile-header-con ${windowHeight < 650 ? 'no-mobile-header-con' : 'mobile-header-con'}`}>
      <div className={`mobile-brand ${windowHeight < 650 ? 'no-brand' : 'mobile-brand'}`}>
          <img src={nbtcLogo} alt='nbtcLogo' className={`portal-logo-mobile ${windowHeight < 650 ? 'no-logo' : 'portal-logo-mobile'}`}></img>
          <div className="mobile-brandName">
              Your <span style={{ fontWeight: '700' }}>brand</span> 
          </div>
      </div>
      <div className="mobile-head-sub"> 
          <div className={`mobile-heading ${windowHeight < 650 ? 'no-heading' : 'mobile-heading'}`}>
              Heading
          </div>
          <div className={`mobile-subtitle ${windowHeight < 650 ? 'no-subtitle' : 'mobile-subtitle'}`}>
              Subtitle
          </div>
      </div>
    </div>
  )
}

export default MobileHeader