import React, { useContext, useEffect, useState } from 'react';
import { multiStepContext } from '../StepContext';
import otpProcessBar from '../assets/otp-process-bar.png';
import '../Styles/BrandSelectPage.css';
import Axios from 'axios';

const BrandSelectPage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const { setStep, userData, setUserData } = useContext(multiStepContext);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!userData.brandOption) {
      setUserData({ ...userData, brandOption: "1" });
    }
    getOperator();
  }, [setUserData, userData]);

  const getOperator = async () => {
    try {
      const response = await Axios.get(apiURL+'getAllOperator');
      setOptions(response.data);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, '').trim().slice(0, 10); 
    setUserData(prevState => ({ ...prevState, phoneNumber: numericValue }));
  };

  const handleButtonClick = () => {
    if (userData.phoneNumberOption === "ไม่ใช่" && userData.phoneNumber && userData.phoneNumber.length < 10) {
      alert("โปรดกรอกหมายเลขโทรศัพท์ 10 หลัก");
    } else {
      setStep(17);
    }
  };

  const handleInput = () => {
    if (userData.phoneNumberOption === "ไม่ใช่") {
      return (
        <div className="phone-number">
          หมายเลขโทรศัพท์
          <input type="text" className='phone-number-text' value={userData.phoneNumber} onChange={handlePhoneNumberChange} />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <div className="brand-container">
        <div className="brand-process-bar">
          <img src={otpProcessBar} className="brand-pro-bar" alt="OTP Process Bar" />
        </div>
        <div className="brandPage-info">
          โปรดกรอกข้อมูลลงในแบบฟอร์ม
          <br />
          <span style={{ fontSize: '16' }}>Please fill out the form with your information</span>
        </div>
        <div className="brand-choice">
          เลือกเครือข่ายที่ต้องการ
          <select className="brand-option" value={userData.brandOption} onChange={(e) => setUserData({ ...userData, brandOption: e.target.value })}>
            {options.map(option => (
              <option key={option.id} value={option.id}>{option.company_name}</option>
            ))}
          </select>
        </div>
        {handleInput()}
        <div className="form-bt">
          <button onClick={() => setStep(6)} className="form-back-bt">ย้อนกลับ</button>
          <button onClick={handleButtonClick} className="form-next-bt">ต่อไป</button>
        </div>
      </div>
    </div>
  )
}

export default BrandSelectPage;
