import React, { useContext , useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import serviceProcessBar from '../assets/service-process-bar.png';
import '../Styles/ServicePage.css'

const ServicePage = () => {
  const {setStep, userData, setUserData} = useContext(multiStepContext);

  const handleBackStep = () => {
    if (userData['selectedOption'] === 4) {
      setStep(5); 
    } else {
      setStep(4);
    }
  };

  useEffect(() => {
    if (!userData.phoneNumberOption) {
      setUserData({ ...userData, phoneNumberOption: "ไม่ใช่" });
    }
  }, [setUserData, userData]);

  return (
    <div className="service-container">
        <div className="service-process-bar">
            <img src={serviceProcessBar} className="service-pro-bar" alt="Service Process Bar" />
        </div>
        <div className="service-info">
            โปรดกรอกข้อมูลลงในแบบฟอร์ม
            <br />
            <span style={{ fontSize: '16' }}>Please fill out  the form with your information</span>
        </div>
        <div className="service-choice">
            ท่านประสงค์ที่จะ
            <select className="service-option" value={userData['phoneNumberOption']} onChange={(e) => setUserData({...userData, "phoneNumberOption": e.target.value})}>
              <option value="ไม่ใช่">ใช้หมายเลขโทรศัพท์เดิม</option>
              <option value="ใช่">รับซิมการ์ดใหม่</option>
            </select>
        </div>
        <div className="form-bt">
            <button onClick={handleBackStep} className="form-back-bt">ย้อนกลับ</button>
            <button onClick={() => setStep(7)} className="form-next-bt">ต่อไป</button>
        </div>
    </div>
  )
}

export default ServicePage