import React from 'react'
import aisLogo from '../assets/ais-logo.png'
import trueLogo from '../assets/true-logo.png';
import dtacLogo from '../assets/dtac-logo.png';
import '../Styles/PortalFooter.css'

const PortalFooter = () => {
  return (
    <div className="portal-footer">
        <div className="brand-footer">
            <img src={aisLogo} className="aisLogo" alt='aisLogo'></img>
            <img src={trueLogo} className="trueLogo" alt='trueLogo'></img>
            <img src={dtacLogo} className="dtacLogo" alt='dtacLogo'></img>
        </div>
    </div>
  )
}

export default PortalFooter