import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/CompleteStatus.css';
import completeStatus from '../assets/complete-status.png';

const CompleteStatusPage = () => {
  const {setStep, userData, setUserData} = useContext(multiStepContext);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="complete-container">
        <div className='complete-status'>
            ดําเนินการเสร็จสิ้น
            <br/>
            <span style={{ fontSize: '16' }}>Completed!</span>
        </div>
        <div className="complete-img-container">
          <img src={completeStatus} className={`notFound-img ${windowHeight < 650 ? 'hidden-notFound' : 'notFound-img'}`}></img>
        </div>
        <div className="complete-text">
        </div>
        <div className="complete-status-con">
            <button onClick={() => setStep(1)} className="complete-status-bt">กลับสู่หน้าหลัก</button>
        </div>
    </div>
  )
}

export default CompleteStatusPage