import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import Map from '../tools/Map';
import '../Styles/MapPage.css';
import 'leaflet/dist/leaflet.css';
import Axios from 'axios';

const MapPage = () => {
    const apiURL = process.env.REACT_APP_API_URL;
    const { setStep, userData, setUserData } = useContext(multiStepContext);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [location, setLocation] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [branch, setBranch] = useState([]);
    const [selectedSubLocation, setSelectedSubLocation] = useState("");
    const [nextDisabled, setNextDisabled] = useState(true); // State to manage the disabled state of the next button

    const getLocation = async () => {
        try {
            const response = await Axios.get(apiURL+`getLocation`);
            setLocation(response.data.branch);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    }

    const getSubLocation = async (selectedLocation) => {
        try {
            const response = await Axios.get(apiURL+`getBranch/${userData.serviceID}/${selectedLocation.name}`);
            const branchData = response.data.OpBranch;
            setBranch(branchData);
        } catch (error) {
            console.error('Error fetching sub-locations:', error);
        }
    }

    const handleLocationChange = (event) => {
        const selectedOption = event.target.selectedOptions[0];
        const selectedId = selectedOption.value;
        const selectedName = selectedOption.text;
        const lattitude = selectedOption.getAttribute('lat');
        const longtitude = selectedOption.getAttribute('long');
        setUserData({ ...userData, locationId: selectedId, locationName: selectedName, locationLat: lattitude, locationLong: longtitude });
        setSelectedLocation({ id: selectedId, name: selectedName, lat: lattitude, lon: longtitude });

        setSelectedSubLocation("");
        setBranch([]); // Clear branch options when location changes
        setNextDisabled(true); 
    }
    
    const handleSubLocationChange = (event) => {
        const selectedSubName = event.target.value;
        const selectedSubId = event.target.selectedOptions[0].getAttribute('data-id'); // If branch ID is included in data-id attribute
        setSelectedSubLocation(selectedSubName);
        setUserData({ ...userData, branchName: selectedSubName, branchId: selectedSubId });
        setNextDisabled(false);
    }

    useEffect(() => {
        getLocation();
    }, []);

    useEffect(() => {
        if (selectedLocation.id && selectedLocation.name) {
            getSubLocation(selectedLocation);
        }
    }, [selectedLocation]);

    return (
        <div className={`location-body ${windowHeight < 650 ? 'small-body' : 'location-body'}`}>
            <div className="location-container">
                <div className="location-area-con">
                    <div className="location-info">
                        รับซิมการ์ดที่ศูนย์บริการใกล้บ้านของท่าน
                        <br />
                        <span style={{ fontSize: '16' }}>You may choose the nearest service center to pick up your new SIM card.</span>
                    </div>
                    <div className="location-fill-area">
                        <div className='location-id-con'>
                            ศูนย์บริการ
                            <select id="dropdown" className="location-choice" onChange={handleLocationChange}>
                                <option value="" disabled selected>โปรดเลือกจังหวัด</option>
                                {location.map(location => (
                                    <option key={location.id} value={location.id} lat={location.lat} long={location.Long}>{location.province}</option>
                                ))}
                            </select>
                        </div>
                        <div style={{ marginTop: '-10px' }}>
                            {selectedLocation && (
                                <div className='sub-location-con'>
                                    <select id="sub-dropdown" className="location-choice" onChange={handleSubLocationChange} value={selectedSubLocation}>
                                        <option value="" disabled>โปรดเลือกสาขา</option>
                                        {branch.map(sub => (
                                            <option key={sub.id} value={sub.name} data-id={sub.id}>{sub.name}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </div>
                        <div className={`map ${windowHeight < 650 ? 'map-small' : 'map'}`}>
                            <Map/>
                        </div>
                        <div className={`map-bt ${windowHeight < 650 ? 'map-bt-small' : 'map-bt'}`}>
                            <button onClick={() => setStep(11)} className="map-back-bt">ย้อนกลับ</button>
                            <button onClick={() => setStep(13)} className={`map-next-bt ${nextDisabled ? 'disabled' : ''}`} disabled={nextDisabled}>ยืนยันข้อมูล</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MapPage;
